<template>
  <div class="container xx">
    <div class="alimentacao">
      <div class="theader">
        <img src="@/assets/empresas.png" width="50px" alt=""> &nbsp;&nbsp; <h2 class="title"> Decoração de empresas:</h2>
      </div>
      <div class="ttext">
        Desenvolvemos Eventos Corporativos como: Decoração do ambiente de trabalho em Datas Comemorativas ( Aniversário, Festa Junina, Natal) e Datas Pontuais como: Meio Ambiente, Orientação de Segurança do Trabalho e Outros. Oferecemos serviços de Alimentação ( Coffee Breack, Almoço e Lanches). Todo este trabalho oferecido às empresas obedece rigorosamente as normas de Segurança com todos os colaboradores passando pelo curso de Integração dando assim todas condições para a execução de nossos serviços.
      </div>
    </div>
    <h2 class="title">Informações:</h2>
    <ul>
      <li>Mais fotos de mesas no: <a href="https://www.facebook.com/decoracoes.goiania.9" target="_blank">Facebook</a> e <a href="https://www.instagram.com/decoracoes_goiania/" target="_blank">Instagram</a></li>
      <li>Para seu conforto, a assinatura do contrato pode ser feita em nossa loja ou enviado para nosso email escaneado.</li>
    </ul>
    <h2 class="title">Algumas fotos de nosso trabalho</h2> 
    <Painel :images="images" :hasMore="false" />
    <div style="text-align: center;"><button class="more" v-if="hasMore" @click="addMore">Carregar Mais Fotos</button></div>
    
  </div>
</template>

<script>
import Painel from '@/components/Painel.vue'

export default {
  metaInfo: {
    title: 'Decoração de Eventos Corporativos',
    titleTemplate: '%s ← Decorações Goiânia',
  },
  data(){
    return {
      images: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-1.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-1.jpg",
          "title": "Empresas-1"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-10.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-10.jpg",
          "title": "Empresas-10"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-11.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-11.jpg",
          "title": "Empresas-11"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-12.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-12.jpg",
          "title": "Empresas-12"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-13.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-13.jpg",
          "title": "Empresas-13"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-14.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-14.jpg",
          "title": "Empresas-14"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-15.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-15.jpg",
          "title": "Empresas-15"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-16.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-16.jpg",
          "title": "Empresas-16"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-17.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-17.jpg",
          "title": "Empresas-17"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-18.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-18.jpg",
          "title": "Empresas-18"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-19.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-19.jpg",
          "title": "Empresas-19"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-2.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-2.jpg",
          "title": "Empresas-2"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-20.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-20.jpg",
          "title": "Empresas-20"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-21.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-21.jpg",
          "title": "Empresas-21"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-22.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-22.jpg",
          "title": "Empresas-22"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-23.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-23.jpg",
          "title": "Empresas-23"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-24.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-24.jpg",
          "title": "Empresas-24"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-25.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-25.jpg",
          "title": "Empresas-25"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-26.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-26.jpg",
          "title": "Empresas-26"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-3.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-3.jpg",
          "title": "Empresas-3"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-4.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-4.jpg",
          "title": "Empresas-4"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-5.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-5.jpg",
          "title": "Empresas-5"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-6.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-6.jpg",
          "title": "Empresas-6"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-7.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-7.jpg",
          "title": "Empresas-7"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-8.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-8.jpg",
          "title": "Empresas-8"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/empresas_thumb/Empresas-9.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/empresas2/Empresas-9.jpg",
          "title": "Empresas-9"
        }
      ]

    }
  },
  components: {
    Painel
  }
}
</script>


<style scoped>
.theader{
  height: 70px;;
  display: flex;
  align-items: center;
}

.ttext{
  margin-top: 7px;
  text-indent: 20px;
}

.title{
  font-weight: 100;
  color: #910004;
}

</style>
