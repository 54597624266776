import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import MesaInfantil from './views/MesaInfantil.vue'
import Empresa from './views/Empresa.vue'
import Casamento from './views/Casamento.vue'
import DecEmpresas from './views/DecEmpresas.vue'
import Alimentacao from './views/Alimentacao.vue'
import Contato from './views/Contato.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/empresa',
      component: Empresa
    },
    {
      path: '/decoracao-mesa-infantil',
      component: MesaInfantil
    },
    {
      path: '/decoracao-de-casamento',
      component: Casamento
    },
    {
      path: '/decoracao-de-empresas',
      component: DecEmpresas
    },
    {
      path: '/alimentacao',
      component: Alimentacao
    },
    {
      path: '/contato',
      component: Contato
    }/*
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" * / './views/About.vue')
    } */
  ]
})
