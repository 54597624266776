<template>
  <div class="container xx">
      <div class="casamento">
        <div class="theader">
          <img src="@/assets/casa.png" height="50px;" alt=""> &nbsp;&nbsp;<h2 class="title"> Decoração de Casamento:</h2>
        </div>
        <div class="ttext">
          Aqui seu sonho vira realidade com a decoração do seu casamento completa (Igreja e Salão) fazendo com que você possa recordar-se para a vida inteira de um momento único em sua vida. Seu momento marcado com o glamour que você merece. Fazemos decoração com Flores Importadas e Nacionais e Artificiais.
        </div>
      </div>
    <h2 class="title">Informações:</h2>
    <ul>
      <li>Mais fotos de casamento em: <a href="https://www.facebook.com/decoracoes.goiania.9" target="_blank">Facebook</a> e <a href="https://instagram.com/decoracoes_goiania/" target="_blank">Instagram</a></li>
      <li>Para seu conforto, a assinatura do contrato pode ser feita em nossa loja ou enviado para nosso email escaneado.</li>
      <li>Visitando nossa loja, podemos mostrar algumas peças de suas mesas preferidas.</li>
    </ul>
    <h2 class="title">Alguns de nossos trabalhos passarela</h2> 
    <Painel :images="imagesPassarela" :hasMore="hasMorePassarela" @loadMore="addMorePassarela"/>
    <div style="text-align: center;"><button class="more" v-if="hasMorePassarela" @click="addMorePassarela">Carregar Mais Fotos Passarela</button></div>
    <h2 class="title">Alguns de nossos trabalhos de mesa do bolo</h2> 
    <Painel :images="imagesMesa" :hasMore="hasMoreMesa" @loadMore="addMoreMesa"/>
    <div style="text-align: center;"><button class="more" v-if="hasMoreMesa" @click="addMoreMesa">Carregar Mais Fotos Mesa de Bolo</button></div>
    <h2 class="title">Alguns de nossos trabalhos de salão</h2> 
    <Painel :images="imagesSalao" :hasMore="hasMoreSalao" @loadMore="addMoreSalao"/>
    <div style="text-align: center;"><button class="more" v-if="hasMoreSalao" @click="addMoreSalao">Carregar Mais Fotos Salao</button></div>
    <h2 class="title">Alguns de nossos trabalhos de hall de entrada</h2> 
    <Painel :images="imagesHall" :hasMore="hasMoreHall" @loadMore="addMoreHall"/>
    <div style="text-align: center;"><button class="more" v-if="hasMoreHall" @click="addMoreHall">Carregar Mais Fotos de Hall</button></div>
    
  </div>
</template>

<script>
import Painel from '@/components/Painel.vue'
export default {
  metaInfo: {
    title: 'Decoração de Casamento: Passarela, Mesa de Bolo, Hall de Entrada e Salão',
    titleTemplate: '%s ← Decorações Goiânia',
  },
  data(){
    return {
      imagesPassarela: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-15.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-15.jpg",
          "title": "Passarela-15"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-10.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-10.jpg",
          "title": "Passarela-10"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-11.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-11.jpg",
          "title": "Passarela-11"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-12.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-12.jpg",
          "title": "Passarela-12"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-13.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-13.jpg",
          "title": "Passarela-13"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-14.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-14.jpg",
          "title": "Passarela-14"
        }
      ],
      imagesHall: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-1.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-1.jpg",
          "title": "Hall-1"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-10.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-10.jpg",
          "title": "Hall-10"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-2.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-2.jpg",
          "title": "Hall-2"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-3.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-3.jpg",
          "title": "Hall-3"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-4.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-4.jpg",
          "title": "Hall-4"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-5.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-5.jpg",
          "title": "Hall-5"
        }
      ],
      imagesMesa: [
          {
            "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-1.jpg",
            "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-1.jpg",
            "title": "Mesa-De-Bolo-1"
          },
          {
            "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-10.jpg",
            "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-10.jpg",
            "title": "Mesa-De-Bolo-10"
          },
          {
            "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-11.jpg",
            "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-11.jpg",
            "title": "Mesa-De-Bolo-11"
          },
          {
            "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-2.jpg",
            "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-2.jpg",
            "title": "Mesa-De-Bolo-2"
          },
          {
            "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-3.jpg",
            "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-3.jpg",
            "title": "Mesa-De-Bolo-3"
          },
          {
            "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-4.jpg",
            "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-4.jpg",
            "title": "Mesa-De-Bolo-4"
          }
      ],
      imagesSalao: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-1.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-1.jpg",
          "title": "Salao-1"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-10.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-10.jpg",
          "title": "Salao-10"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-11.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-11.jpg",
          "title": "Salao-11"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-12.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-12.jpg",
          "title": "Salao-12"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-2.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-2.jpg",
          "title": "Salao-2"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-3.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-3.jpg",
          "title": "Salao-3"
        }
      ],
      moreSalao: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-4.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-4.jpg",
          "title": "Salao-4"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-5.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-5.jpg",
          "title": "Salao-5"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-6.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-6.jpg",
          "title": "Salao-6"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-7.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-7.jpg",
          "title": "Salao-7"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-8.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-8.jpg",
          "title": "Salao-8"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/salao/Salao-9.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/salao/Salao-9.jpg",
          "title": "Salao-9"
        }
      ],
      moreHall: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-6.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-6.jpg",
          "title": "Hall-6"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-7.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-7.jpg",
          "title": "Hall-7"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-8.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-8.jpg",
          "title": "Hall-8"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/hall/Hall-9.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/hall/Hall-9.jpg",
          "title": "Hall-9"
        }
      ],
      moreMesa: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-5.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-5.jpg",
          "title": "Mesa-De-Bolo-5"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-6.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-6.jpg",
          "title": "Mesa-De-Bolo-6"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-7.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-7.jpg",
          "title": "Mesa-De-Bolo-7"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-8.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-8.jpg",
          "title": "Mesa-De-Bolo-8"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/mesa-de-bolo/Mesa-De-Bolo-9.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/mesa-de-bolo/Mesa-De-Bolo-9.jpg",
          "title": "Mesa-De-Bolo-9"
        }

      ],
      morePassarela: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-16.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-16.jpg",
          "title": "Passarela-16"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-17.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-17.jpg",
          "title": "Passarela-17"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-18.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-18.jpg",
          "title": "Passarela-18"
        },

        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-2.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-2.jpg",
          "title": "Passarela-2"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-20.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-20.jpg",
          "title": "Passarela-20"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-21.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-21.jpg",
          "title": "Passarela-21"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-26.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-26.jpg",
          "title": "Passarela-26"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-27.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-27.jpg",
          "title": "Passarela-27"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-28.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-28.jpg",
          "title": "Passarela-28"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-29.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-29.jpg",
          "title": "Passarela-29"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-3.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-3.jpg",
          "title": "Passarela-3"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-31.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-31.jpg",
          "title": "Passarela-31"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-32.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-32.jpg",
          "title": "Passarela-32"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-34.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-34.jpg",
          "title": "Passarela-34"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-35.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-35.jpg",
          "title": "Passarela-35"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-36.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-36.jpg",
          "title": "Passarela-36"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-37.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-37.jpg",
          "title": "Passarela-37"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-4.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-4.jpg",
          "title": "Passarela-4"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-5.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-5.jpg",
          "title": "Passarela-5"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-6.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-6.jpg",
          "title": "Passarela-6"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-7.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-7.jpg",
          "title": "Passarela-7"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-8.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-8.jpg",
          "title": "Passarela-8"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/decoracao_thumb/passarela/Passarela-9.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/decoracao2/passarela/Passarela-9.jpg",
          "title": "Passarela-9"
        }
      ]
    }
  },
  computed: {
    hasMorePassarela(){
      return this.morePassarela.length !== 0
    },
    hasMoreSalao(){
      return this.moreSalao.length !== 0
    },
    hasMoreHall(){
      return this.moreHall.length !== 0
    },
    hasMoreMesa(){
      return this.moreMesa.length !== 0
    }
  },
  methods: {
    addMorePassarela(){
      this.imagesPassarela = this.imagesPassarela.concat(this.morePassarela.splice(0, 6))
    },
    addMoreHall(){
      this.imagesHall = this.imagesHall.concat(this.moreHall.splice(0, 6))
    },
    addMoreSalao(){
      this.imagesSalao = this.imagesSalao.concat(this.moreSalao.splice(0, 6))
    },
    addMoreMesa(){
      this.imagesMesa = this.imagesMesa.concat(this.moreMesa.splice(0, 6))
    }
  },
  components: {
    Painel
  }
}
</script>



<style scoped>
.theader{
  height: 70px;;
  display: flex;
  align-items: center;
}

.ttext{
  margin-top: 7px;
  text-indent: 20px;
}

.title{
  font-weight: 100;
  color: #910004;
}


.more{
  background: #910004;
  color: white;
  padding: 10px 30px;
  border: 0;
  border-radius: 3px;
  margin: 20px;
  cursor: pointer;
}

</style>

