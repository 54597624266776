<template>
  <div class="container">
    <div class="box">
      <div class="lft">
        <div class="dt"><img src="@/assets/whats.png" height="30px" alt="">&nbsp;Celma - (62)98574-0509</div>
        <br>
        <div class="dt"><img src="@/assets/whats.png" height="30px" alt="">&nbsp;Osmar - (62)98531-6885</div>
      </div>
      <div class="rgt">
        <div class="dt"><img src="@/assets/phone.png" height="25px" alt="">&nbsp; (62)3921-5594</div>
      </div>
    </div>
    <h2 class="title">Por Email:</h2> 
    <p>decoracoesgoiania@gmail.com</p>
      
    <h2 class="title">Horário de atendimento:</h2>
    <p>Atendemos de segunda a sexta em horário comercial. Em finais de semana em virtude de estarmos realizando os eventos o contato pode ser    feito através dos celulares da Celma e do Osmar acima listados.</p> 

    <h2 class="title">Mapa de como chegar em nossa loja:</h2>  
    <div class="mapa">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3821.5654109563975!2d-49.282541!3d-16.698617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7ff01f938303b33!2zRGVjb3Jhw6fDtWVzIEdvaWFuaWE!5e0!3m2!1sen!2sbr!4v1535561915840" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>
  </div>
</template>


<script>
export default {
  metaInfo: {
    title: 'Contato',
    titleTemplate: '%s ← Decorações Goiânia',
  },
}
</script>


<style scoped>
.lft, .rgt{
  text-align: center;
}

.mapa iframe{
  width: 100%;
  height: 500px;
}

.title{
  font-weight: 100;
  color: #910004;
}

.dt{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-align: right;
}
</style>
