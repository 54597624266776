<template>
  <div class="container xx">
    <div class="alimentacao">
      <div class="theader">
        <img src="@/assets/food.png" width="50px" alt=""> &nbsp;&nbsp; <h2 class="title"> Alimentação:</h2>
      </div>
      <div class="ttext">
        Pensando em sua comodidade oferecemos para os nossos clientes buffet e uma variedade de lanches que vai fazer com que você ofereça aos seus convidados uma qualidade na alimentação que é preparada com todos os cuidados pertinentes e uma equipe treinada que prepara os devidos alimentos na hora.
      </div>
    </div>
    <h2 class="title">Informações:</h2>
    <ul>
      <li>As barraquinha de Pipoca e Algodão Doce são servidas à vontade durante o período de 4 horas e as demais são vendidas o centro(100 Unidades), sem limite de tempo para consumo.</li>
      <li>As barraquinhas de Crepe, Pastel e Pipoca temos vários sabores e fica à sua escolha.</li>
      <li>Mais fotos de mesas no: <a href="https://facebook.com/decoracoes.goiania.9" target="_blank">Facebook</a> e <a href="https://instagram.com/decoracoes_goiania/" target="_blank">Instagram</a></li>
      <li>Para seu conforto, a assinatura do contrato pode ser feita em nossa loja ou enviado para nosso email escaneado.</li>
    </ul>
    <h2 class="title">Algumas fotos de nosso trabalho</h2> 
    <Painel :images="images" :hasMore="false" />
    <div style="text-align: center;"><button class="more" v-if="hasMore" @click="addMore">Carregar Mais Fotos</button></div>
    
  </div>
</template>

<script>
import Painel from '@/components/Painel.vue'
export default {
  metaInfo: {
    title: 'Alimentação: Barraquinhas e Buffet',
    titleTemplate: '%s ← Decorações Goiânia',
  },
  data(){
    return {
      images: [
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-1.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-1.jpg",
          "title": "Barraquinha-1"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-10.jpeg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-10.jpeg",
          "title": "Barraquinha-10"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-11.jpeg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-11.jpeg",
          "title": "Barraquinha-11"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-12.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-12.jpg",
          "title": "Barraquinha-12"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-13.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-13.jpg",
          "title": "Barraquinha-13"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-14.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-14.jpg",
          "title": "Barraquinha-14"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-15.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-15.jpg",
          "title": "Barraquinha-15"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-2.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-2.jpg",
          "title": "Barraquinha-2"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-3.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-3.jpg",
          "title": "Barraquinha-3"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-4.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-4.jpg",
          "title": "Barraquinha-4"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-5.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-5.jpg",
          "title": "Barraquinha-5"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-6.png",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-6.png",
          "title": "Barraquinha-6"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-7.jpg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-7.jpg",
          "title": "Barraquinha-7"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-8.jpeg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-8.jpeg",
          "title": "Barraquinha-8"
        },
        {
          "thumb": "https://decoracoesgoiania.com/fotos/barraquinhas_thumb/Barraquinha-9.jpeg",
          "fullsize": "https://decoracoesgoiania.com/fotos/barraquinhas2/Barraquinha-9.jpeg",
          "title": "Barraquinha-9"
        }
      ]
    }
  },
  components: {
    Painel
  }
}
</script>



<style scoped>
.theader{
  height: 70px;;
  display: flex;
  align-items: center;
}

.ttext{
  margin-top: 7px;
  text-indent: 20px;
}

.title{
  font-weight: 100;
  color: #910004;
}

</style>
