<template>
  <div class="container xx">
      <div class="mesa-infantil">
        <div class="theader">
          <img src="@/assets/kid2.png" height="50px;" alt=""> &nbsp;&nbsp;<h2 class="title"> Mesa Infantil:</h2>
        </div>
        <div class="ttext">
          Nossa empresa possui os mais variados e atualizados temas para a montagem de sua mesa infantil e fazer de sua festa um grande evento. Nossas peças todas com cuidados especiais de manutenção para que o cliente possa presentear com qualidade o aniversariante e seus convidados. Temos mais de 50 temas de mesas. 
        </div>
      </div>
    <h2 class="title">Informações:</h2>
    <ul>
      <li>Todas as mesas inclui: 02 Mesas provençal com Iluminação, 01 tapete piso, 01 painel ou cortina de 3 metros, 01 arco com balões, 01 cesto para presente, 02 bandejas para doce e 01 Piruliteiro. </li>
      <li>Abaixo estamos mostrando apenas algumas de nossas mesas. Caso queira alguma que não está listada abaixo,
não deixe de entrar em contato conosco.</li>
      <li style="font-size: 21px;">Mais fotos e diferentes temas no: <a href="https://www.facebook.com/decoracoes.goiania.9" target="_blank">Facebook</a> e <a href="https://www.instagram.com/decoracoes_goiania/" target="_blank">Instagram</a></li>
      <li>Para seu conforto, a assinatura do contrato pode ser feita em nossa loja ou enviado para nosso email escaneado.</li>
      <li>Visitando nossa loja, podemos mostrar algumas peças de suas mesas preferidas.</li>
    </ul>
    <h2 class="title">Alguns de nossos temas para festa infantil</h2> 
    <Painel :images="images" :hasMore="hasMore" @loadMore="addMore"/>
    <div style="text-align: center;"><button class="more" v-if="hasMore" @click="addMore">Carregar Mais Fotos</button></div>
    
  </div>
</template>

<script>
import Painel from "@/components/Painel.vue";
export default {
  metaInfo: {
    title: "Decoração de Mesa Infantil Mais de 50 Temas",
    titleTemplate: "%s ← Decorações Goiânia",
  },
  data() {
    return {
      images: [
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/A%20BELA%20E%20A%20FERA%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/A%20BELA%20E%20A%20FERA%20II.jpg",
          title: "A BELA E A FERA II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/A%20BELA%20E%20A%20FERA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/A%20BELA%20E%20A%20FERA.jpg",
          title: "A BELA E A FERA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/ABELHINHA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/ABELHINHA.jpg",
          title: "ABELHINHA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/ANGRY%20BIRD.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/ANGRY%20BIRD.jpg",
          title: "ANGRY BIRD",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/ANIMAIS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/ANIMAIS.jpg",
          title: "ANIMAIS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/ARCA%20DE%20NOE.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/ARCA%20DE%20NOE.jpg",
          title: "ARCA DE NOE",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BACKARDIGANS%20FEMININA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BACKARDIGANS%20FEMININA.jpg",
          title: "BACKARDIGANS FEMININA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BACKARDIGANS%20MASC..jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BACKARDIGANS%20MASC..jpg",
          title: "BACKARDIGANS MASC",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BAILARINA%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BAILARINA%20II.jpg",
          title: "BAILARINA II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BAILARINA%20III.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BAILARINA%20III.jpg",
          title: "BAILARINA III",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BAILARINA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BAILARINA.jpg",
          title: "BAILARINA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BARBIE%20PARIS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BARBIE%20PARIS.jpg",
          title: "BARBIE PARIS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BARBIE.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BARBIE.jpg",
          title: "BARBIE",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BATIZADO%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BATIZADO%20II.jpg",
          title: "BATIZADO II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BATIZADO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BATIZADO.jpg",
          title: "BATIZADO",
        },
      ],
      more: [
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BATMAN.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BATMAN.jpg",
          title: "BATMAN",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BEN%2010.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BEN%2010.jpg",
          title: "BEN 10",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BETE%20BOOP.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BETE%20BOOP.jpg",
          title: "BETE BOOP",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BOB%20ESPONJA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BOB%20ESPONJA.jpg",
          title: "BOB ESPONJA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BONECAS%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BONECAS%20II.jpg",
          title: "BONECAS II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BONECAS%20PRINCESAS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BONECAS%20PRINCESAS.jpg",
          title: "BONECAS PRINCESAS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BONECAS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BONECAS.jpg",
          title: "BONECAS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BRANCA%20DE%20NEVE.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BRANCA%20DE%20NEVE.jpg",
          title: "BRANCA DE NEVE",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/BUTECO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/BUTECO.jpg",
          title: "BUTECO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CARROS%20III.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CARROS%20III.jpg",
          title: "CARROS III",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CHA%20DE%20FRAUDAS%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CHA%20DE%20FRAUDAS%20II.jpg",
          title: "CHA DE FRAUDAS II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CHA%20DE%20FRAUDAS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CHA%20DE%20FRAUDAS.jpg",
          title: "CHA DE FRAUDAS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CHUVA%20DE%20AMOR%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CHUVA%20DE%20AMOR%20II.jpg",
          title: "CHUVA DE AMOR II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CHUVA%20DE%20AMOR.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CHUVA%20DE%20AMOR.jpg",
          title: "CHUVA DE AMOR",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CINDEREL%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CINDEREL%20II.jpg",
          title: "CINDEREL II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CINDERELA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CINDERELA.jpg",
          title: "CINDERELA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CIRCO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CIRCO.jpg",
          title: "CIRCO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/COCORICO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/COCORICO.jpg",
          title: "COCORICO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/COROA%20MASCULINA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/COROA%20MASCULINA.jpg",
          title: "COROA MASCULINA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/CORUJAS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/CORUJAS.jpg",
          title: "CORUJAS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/DINOSSAUROS%20(2).jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/DINOSSAUROS%20(2).jpg",
          title: "DINOSSAUROS (2)",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/DORA%20AVENTUREIRA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/DORA%20AVENTUREIRA.jpg",
          title: "DORA AVENTUREIRA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/DRA%20BRINQUEDO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/DRA%20BRINQUEDO.jpg",
          title: "DRA BRINQUEDO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/ELEFANTINHO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/ELEFANTINHO.jpg",
          title: "ELEFANTINHO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FAZENDINHA%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FAZENDINHA%20II.jpg",
          title: "FAZENDINHA II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FAZENDINHA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FAZENDINHA.jpg",
          title: "FAZENDINHA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FLAMINGO%20I.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FLAMINGO%20I.jpg",
          title: "FLAMINGO I",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FLAMINGO%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FLAMINGO%20II.jpg",
          title: "FLAMINGO II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FROZEN%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FROZEN%20II.jpg",
          title: "FROZEN II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FROZEN.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FROZEN.jpg",
          title: "FROZEN",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FUTEBOL%20CORINTIANS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FUTEBOL%20CORINTIANS.jpg",
          title: "FUTEBOL CORINTIANS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FUTEBOL%20I.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FUTEBOL%20I.jpg",
          title: "FUTEBOL I",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FUTEBOL%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FUTEBOL%20II.jpg",
          title: "FUTEBOL II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/FUTEBOL%20NEUTRO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/FUTEBOL%20NEUTRO.jpg",
          title: "FUTEBOL NEUTRO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/GALINHA%20PINTADINHA%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/GALINHA%20PINTADINHA%20II.jpg",
          title: "GALINHA PINTADINHA II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/GALINHA%20PINTADINHA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/GALINHA%20PINTADINHA.jpg",
          title: "GALINHA PINTADINHA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/GATA%20MARRY.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/GATA%20MARRY.jpg",
          title: "GATA MARRY",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/HOMEM%20ARANHA%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/HOMEM%20ARANHA%20II.jpg",
          title: "HOMEM ARANHA II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/JARDIM%20DE%20BONECAS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/JARDIM%20DE%20BONECAS.jpg",
          title: "JARDIM DE BONECAS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/JARDIM.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/JARDIM.jpg",
          title: "JARDIM",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/JOANINHA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/JOANINHA.jpg",
          title: "JOANINHA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/JUNINA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/JUNINA.jpg",
          title: "JUNINA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/LARY%20BAG.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/LARY%20BAG.jpg",
          title: "LARY BAG",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/LEGO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/LEGO.jpg",
          title: "LEGO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/LIGA%20DA%20JUSTIÇA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/LIGA%20DA%20JUSTIÇA.jpg",
          title: "LIGA DA JUSTIÇA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MADAGASCAR.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MADAGASCAR.jpg",
          title: "MADAGASCAR",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MARIO%20BROSS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MARIO%20BROSS.jpg",
          title: "MARIO BROSS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MARSHA%20E%20O%20URSO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MARSHA%20E%20O%20URSO.jpg",
          title: "MARSHA E O URSO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MICKEY.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MICKEY.jpg",
          title: "MICKEY",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MINE%20ROSA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MINE%20ROSA.jpg",
          title: "MINE ROSA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MINECRAFIT.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MINECRAFIT.jpg",
          title: "MINECRAFIT",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MINIE%20ROSA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MINIE%20ROSA.jpg",
          title: "MINIE ROSA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MINIE%20VERMELHA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MINIE%20VERMELHA.jpg",
          title: "MINIE VERMELHA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MINIOS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MINIOS.jpg",
          title: "MINIOS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MOANA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MOANA.jpg",
          title: "MOANA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MORANGUINHO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MORANGUINHO.jpg",
          title: "MORANGUINHO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MULHER%20MARAVILHA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MULHER%20MARAVILHA.jpg",
          title: "MULHER MARAVILHA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MUNDO%20BITA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MUNDO%20BITA.jpg",
          title: "MUNDO BITA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/MUSICAL.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/MUSICAL.jpg",
          title: "MUSICAL",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/NEMO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/NEMO.jpg",
          title: "NEMO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/O%20VINGADORES%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/O%20VINGADORES%20II.jpg",
          title: "O VINGADORES II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/OS%20INCRIVEIS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/OS%20INCRIVEIS.jpg",
          title: "OS INCRIVEIS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/OS%20VINGADORES.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/OS%20VINGADORES.jpg",
          title: "OS VINGADORES",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/OS%20VINGDORES%20RUSTICA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/OS%20VINGDORES%20RUSTICA.jpg",
          title: "OS VINGDORES RUSTICA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PASSARINHOS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PASSARINHOS.jpg",
          title: "PASSARINHOS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PASSAROS%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PASSAROS%20II.jpg",
          title: "PASSAROS II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PATATI%20PATATA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PATATI%20PATATA.jpg",
          title: "PATATI PATATA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PATRULHA%20CANINA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PATRULHA%20CANINA.jpg",
          title: "PATRULHA CANINA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PEPPA%20III.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PEPPA%20III.jpg",
          title: "PEPPA III",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PEQUENO%20PRINCIPE.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PEQUENO%20PRINCIPE.jpg",
          title: "PEQUENO PRINCIPE",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PET.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PET.jpg",
          title: "PET",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PISCINA.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PISCINA.jpg",
          title: "PISCINA",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/POKEMON.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/POKEMON.jpg",
          title: "POKEMON",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/PRINCESAS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/PRINCESAS.jpg",
          title: "PRINCESAS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/SAFARI%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/SAFARI%20II.jpg",
          title: "SAFARI II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/SAFARI.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/SAFARI.jpg",
          title: "SAFARI",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/SININHO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/SININHO.jpg",
          title: "SININHO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/SMILINGUIDO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/SMILINGUIDO.jpg",
          title: "SMILINGUIDO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/SUPER%20WINGS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/SUPER%20WINGS.jpg",
          title: "SUPER WINGS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/TOY%20STORY%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/TOY%20STORY%20II.jpg",
          title: "TOY STORY II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/TURMA%20DA%20MONICA%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/TURMA%20DA%20MONICA%20II.jpg",
          title: "TURMA DA MONICA II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/TURMA%20DO%20MICKEY.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/TURMA%20DO%20MICKEY.jpg",
          title: "TURMA DO MICKEY",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/UNICORNIO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/UNICORNIO.jpg",
          title: "UNICORNIO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/URSINHO%20BALOEIRO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/URSINHO%20BALOEIRO.jpg",
          title: "URSINHO BALOEIRO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/URSINHO%20MARINHEIRO.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/URSINHO%20MARINHEIRO.jpg",
          title: "URSINHO MARINHEIRO",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/URSINHO%20POOF.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/URSINHO%20POOF.jpg",
          title: "URSINHO POOF",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/URSINHOS%20II.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/URSINHOS%20II.jpg",
          title: "URSINHOS II",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/URSINHOS.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/URSINHOS.jpg",
          title: "URSINHOS",
        },
        {
          thumb:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil_thumb/carros.jpg",
          fullsize:
            "https://www.decoracoesgoiania.com/fotos/mesa-infantil2/carros.jpg",
          title: "carros",
        },
      ],
    };
  },
  computed: {
    hasMore() {
      return this.more.length !== 0;
    },
  },
  methods: {
    addMore() {
      this.images = this.images.concat(this.more.splice(0, 6));
    },
  },
  components: {
    Painel,
  },
};
</script>

<style scoped>
.more {
  background: #910004;
  color: white;
  padding: 10px 30px;
  border: 0;
  border-radius: 3px;
  margin: 20px;
  cursor: pointer;
}

.title {
  font-weight: 100;
  color: #910004;
}

.theader {
  height: 70px;
  display: flex;
  align-items: center;
}

.ttext {
  margin-top: 7px;
  text-indent: 20px;
}
</style>
