<template>
  <div class="container">
    <h2 class="title">Nossa Empresa:</h2> 
    <p>Nossa empresa teve início no ano de 1.994, onde nossas atividades iniciais foram: Serviços de Tele-mensagem, Cesta de Café da manhã e Floricultura. Apresento à você cliente amigo uma empresa que à dezessete anos vem se dedicando e realizando uma prestação de serviço com a qualidade que você CLIENTE, quando procura tem a certeza que vai encontrar acima de tudo a qualidade em todos os sentidos para que a satisfação depois do serviço realizado seja uma realidade.</p> 

    <p>Hoje temos à sua disposição os seguintes serviços:</p>
    <ul>
      <li>Decoração de Festas Infantis.</li>
      <li>Decoração de Eventos como: 15 Anos, Casamento, Bodas, Empresas e Formaturas</li>
      <li>Serviços de Buffet com variados cardápios.</li>
      <li>Locação de Brinquedos.</li>
      <li>Barraquinhas de Alimentação</li>
    </ul>
    
    <h2 class="title">Nossa Missão:</h2>
    <p>  Realizar Sonhos e fazer com que o cliente alcance a satisfação de algo que Ele, ao contratar nossos serviços está almejando muito. Realizar sempre com dedicação e qualidade os serviços que temos à disposição de nossos clientes, respeitando seu desejo e vontade de fazer com que as pessoas envolvidas possam também estar recebendo algo muito especial.</p>

    <h2 class="title">Nossa Visão:</h2>
    <p>  Estar sempre atento aos anseios de nossos clientes e fazer com que em qualquer tempo e lugar haja a possibilidade de fazer as pessoas se realizarem com os Serviços prestados pela nossa empresa.</p>

    <h2 class="title">Nossos Valores:</h2>
    <ul>
      <li>Qualidade em tudo o que fazemos.</li>
      <li>Comprometimento.</li>
      <li>Serviço.</li>
      <li>Trabalho em Equipe.</li>
      <li>Diversidade.</li>
      <li>Integridade.</li>
      <li>Continuidade.</li>
      <li>Liderança.</li>
    </ul>

    <h2 class="title">Nossos Serviços</h2>
      <h4 class="title">  Alimentação:</h4>
      <li>Barraquinha em Inox com Cobertura medindo 1mtx1mt.</li>
      <li>Buffet</li>
      <li>Colaborador(a) uniformizado(a) e treinado(a) para o preparo da alimentação no local da festa ou evento.</li>
      <li>Garçons</li>
      <li>Coffee break</li>

      <h4 class="title">  Aniversários:</h4>
      <li>Aparadores</li>
      <li>Arranjos florais tamanho,modelos, estilos e cores variadas.</li>
      <li>Mesas de bistrô.</li>
      <li>Painéis.</li>
      <li>Passarela.</li>
      <li>Pufes de várias cores e tamanhos.</li>
      <li>Tablado para bolo.</li>

      <h4 class="title">  Casamento:</h4>
      <li>Ambientação de entrada com aparadores,pufes, mesas rústicas, tapetes e sofás.</li>
      <li>Arranjos florais tamanho,modelos, estilos e cores variadas.</li>
      <li>Buquês de Noivas.</li>
      <li>Buquês de Damas.</li>
      <li>Cadeiras para os Pais.</li>
      <li>Genuflexório vários modelos.</li>
      <li>Laterais para passarela de várias cores.</li>
      <li>Mesas de bistrô.</li>
      <li>Painéis para os cumprimentos dos noivos.</li>
      <li>Pufes para Damas.</li>
      <li>Passadeira de todas as cores.</li>
      <li>Pedestais em vidro,madeira e ferro.</li>
      <li>Tablado par altar e mesa para o bolo.</li>
      <li>Tapetes.</li>

      <h4 class="title">  Formaturas, Eventos</h4>
      <li>Aparadores.</li>
      <li>Arranjos florais tamanho,modelos, estilos e cores variadas.</li>
      <li>Painéis para fotos.</li>
      <li>Pufes.</li>
      <li>Tapetes.</li>
      <li>Tecidos para mesa Diretiva.</li>

      <h4 class="title">  Mesa Infantil:</h4>
      <li>Mesa principal de 3metros, podendo ser tradicional, de vidro ou provençal.</li>
      <li>Decoração com balões.</li>
      <li>Painel de parede.</li>
      <li>Painel de chão.</li>
      <li>Cesto para Presentes.</li>
      <li>Mesa auxiliar para doces com piruliteiro e suporte para doces.</li>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Mais Sobre Nós',
    titleTemplate: '%s ← Decorações Goiânia',
  },
}
</script>


<style scoped>
.title{

  font-weight: 100;
  color: #910004;
}
</style>
