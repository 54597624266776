<template>
  <div class="painel">
      <el-card :body-style="{ padding: '0px' }" v-for="(image, index) in images" :key="index">
        <div @click="showModal(index)">
          <img :src="image.thumb" class="image">
          <div style="padding: 14px;">
            <span>{{image.title}}</span>
          </div>
        </div>

      </el-card>


      <div class="modal" v-if="showImage" @click="close($event)">
        <div class="block">
          <div class="row1">
            <div class="close" @click="showImage = false">X</div>
          </div>
          <div class="row2">
            <img :src="images[selected].fullsize" alt="">
            <div class="next"><i class="el-icon-arrow-left" @click="go(-1)"></i><i class="el-icon-arrow-right" @click="go(1)"></i></div>
          </div>
          <div class="row3">
            {{images[selected].title}} - Telefone:: (62)3921-5594 - Whatsapp:: (62) 98574-0509
          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  props: ['images', 'hasMore'],
  data(){
    return{
      showImage: false,
      selected: 0
    }
  },
  methods: {
    showModal(index){
      console.log('inside modal')
      this.selected = index
      this.showImage = true
    },
    close(evt){
      if(evt.target.className === 'modal' || evt.target.className === 'close' || evt.target.className === 'block' || evt.target.className === 'row1')
        this.showImage = false
    },
    go(val){
      if(val === -1){
        if(this.selected > 0)
          this.selected -= 1
      }else{
        if(this.selected < this.images.length){
          this.selected += 1
        }
        else if(this.hasMore){
          this.$emit('loadMore')
          this.selected += 1
        }
          
      }
    }
  }
}
</script>

<style scoped>


.row1{
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.painel{
  margin-top: -20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.image{
  width: 100%;
  height: auto;
}

.el-card{
  max-width: 300px;
  margin-top: 15px;
  max-height: 300px;
  cursor: pointer;
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.8)
}

.block{
  padding: 0 10px;
  max-width: 990px;
  margin: 0 auto;
  margin-top: 5vh;
  color: white;
  overflow-y: auto;
  height: 90vh;
}

.row2{
  position: relative;
}

.row2 img{
  width: 100%;
  height: auto;
}

.next{
  position: absolute;
  top: 35%;
  font-size: 40px;
  font-weight: 900;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 21px;
}

.el-icon-arrow-left, .el-icon-arrow-right{
  cursor: pointer;
  background: rgba(255,255,255, 0.2)
}

.close{
  font-weight: 900;
  font-size: 21px;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 770px) {
  .content{
    margin-top: 20px;
  }
  
}

</style>
