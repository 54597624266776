<template>
  <div id="app">
    <Header></Header>
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {
    Header
  }
}
</script>


<style>
html, body{
  margin: 0;
  padding: 0;
  background: #F4F4F8;
  color: #606266;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

.container{
  max-width: 992px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

.box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lft{
  flex: 1;
}
.rgt{
  flex: 1;
}
</style>
