<template>
  <div class="container">
    <div class="slider">
      <img :src="atual" alt="Slide">
    </div>
    <div class="container hr"></div>
    <div class="trabalhos">
      <div class="mesa-infantil">
        <div class="theader">
          <img src="@/assets/kid2.png" height="50px;" alt=""> <span class="title">Mesa Infantil</span>
        </div>
        <div class="ttext">
          Nossa empresa possui os mais variados e atualizados temas para a montagem de sua mesa infantil e fazer de sua festa um grande evento. Nossas peças todas com cuidados especiais de manutenção para que o cliente possa presentear com qualidade o aniversariante e seus convidados. Temos mais de 50 temas de mesas. 
        </div>
      </div>
      <div class="empresas">
        <div class="theader">
          <img src="@/assets/empresas.png" height="50px;" alt=""> <span class="title">Empresas</span>
        </div>
        <div class="ttext">
          Realizamos a decoração da sua empresa para datas comemorativas ou para eventos especiais. Entendemos que cada empresa tem sua necessidade, então buscamos nos adaptar para cada caso. Entre em contato e nos diga o que precisa.
        </div>
      </div>
      <div class="casamento">
        <div class="theader">
          <img src="@/assets/casa.png" height="50px;" alt=""> <span class="title">Casamento</span>
        </div>
        <div class="ttext">
          Aqui seu sonho vira realidade com a decoração do seu casamento completa (Igreja e Salão) fazendo com que você possa recordar-se para a vida inteira de um momento único em sua vida. Seu momento marcado com o glamour que você merece. Fazemos decoração com Flores Importadas e Nacionais e Artificiais.
        </div>
      </div>
      <div class="alimentacao">
        <div class="theader">
          <img src="@/assets/food.png" width="50px" alt=""> <span class="title">Alimentação</span>
        </div>
        <div class="ttext">
          Pensando em sua comodidade oferecemos para os nossos clientes buffet e uma variedade de lanches que vai fazer com que você ofereça aos seus convidados uma qualidade na alimentação que é preparada com todos os cuidados pertinentes e uma equipe treinada que prepara os devidos alimentos na hora.
        </div>
      </div>
    </div>

    <div class="container hr"></div>
    <div class="container ax">
      <p class="title" style="margin: 0px;">Alguns de nossos clientes</p>
      <div class="clientes">
        <div class="cliente"><img src="@/assets/clientes/c10.png" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c11.png" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c12.png" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c13.png" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c14.png" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c1.jpg" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c2.jpg" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c3.jpg" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c4.jpg" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c6.jpg" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c8.jpg" alt=""></div>
        <div class="cliente"><img src="@/assets/clientes/c7.jpg" alt=""></div>
      </div>
    </div>

    <div class="container hr"></div>
    <div class="container ft">
      Endereço: Av. C-198, Qd.493 Lt. 15 Jardim América - Goiânia-GO - Whatsapp:: (62) 98574-0509
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Decoração de Aniversário Infantil, Empresas e Casamentos',
    titleTemplate: '%s ← Decorações Goiânia',
  },
  data(){
    return{
      atual: ''
    }
  },
  created(){
    let self = this
    let counter = Math.floor(Math.random()*100)
    let images = [
      'https://decoracoesgoiania.com/fotos/home/slide01.jpg',
      'https://decoracoesgoiania.com/fotos/home/slide06.jpeg',
      'https://decoracoesgoiania.com/fotos/home/slide07.jpeg'
    ]
    this.atual = images[counter%images.length]
    setInterval(()=> {
      self.atual = images[counter%images.length]
      counter += 1
    }, 4000)
  }
}
</script>

<style scoped>
.ft{
  text-align: center;
  margin-top:10px;
}


.clientes{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.cliente{
  text-align: center;
  height: 60px;
  min-width: 130px;
  margin-top: 10px;
}

.cliente img{
  width: auto;
  height: 100%;
}

.ax{
  background: white;
  margin-top: 10px;
}

.title{
  color: #910004;
  font-size: 18px;
  margin-left: 7px;
}

.mesa-infantil, .empresas, .casamento, .alimentacao{
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
}

.theader{
  height: 70px;;
  display: flex;
  align-items: center;
}

.ttext{
  margin-top: 7px;
  text-indent: 20px;
}

.slider{
  margin-top: -10px;
}
.slider img{
  width: 100%;
}
.hr{
  margin-top: -15px;
  border-bottom: 2px dashed #C0C4CC;
}

.trabalhos{
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}


@media screen and (max-width: 770px) {
  .slider{
    margin-top: -35px;
  }


  .mesa-infantil, .empresas, .casamento, .alimentacao{
    min-width: 270px;
  }
}
</style>
