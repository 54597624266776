import { render, staticRenderFns } from "./Alimentacao.vue?vue&type=template&id=2bc357c8&scoped=true&"
import script from "./Alimentacao.vue?vue&type=script&lang=js&"
export * from "./Alimentacao.vue?vue&type=script&lang=js&"
import style0 from "./Alimentacao.vue?vue&type=style&index=0&id=2bc357c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc357c8",
  null
  
)

component.options.__file = "Alimentacao.vue"
export default component.exports