import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta';

Vue.config.productionTip = false

import {Card} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Meta);

Vue.use(Card);

// defalut install

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
